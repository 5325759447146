import React from "react";
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout";
import { graphql } from "gatsby";
import {
  HcpMobileBanner,
  PageWrapper,
  HcptestResetpatientSection,
  HcpPage
} from "@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle";
import {
  ImportanceOfgeneticTesingWrapper,
  ContainerWrapper,
  EmerFooterBlock,
  TestorRetestPatientSectionWrapper,
  DisclaimerFooterWrapper
} from "./styles";

import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import HTMLRenderer from '@retina-packages/retina-theme-bootstrap/packages/components/HTMLRenderer'
import { Props, PageQueryResult } from "@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes";
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import CircularInfographics from "@retina-packages/retina-theme-bootstrap/packages/components/InfographicDonut/CircularInfographics";
import { HcpGTI } from './constants';
import retinaConfig from '../../../utils/retinaConfigs';
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder, footRefHTMLBuilder, infographicDataBuilder } from '../../../utils';
import { FooterCalloutBlock } from "../../../css/common/style";
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles'

/** Renders genetic-testing-importance-page template
 *
 * @param props props
 * @returns
 */
const GeneticTestingBenefitTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsGTB = props.pageContext
  const htmlStaticFilesGTB = {
    nonSVGImages: pgContextsGTB.nonSVGImages,
    svgMediaImages: pgContextsGTB.svgMediaImages,
    allMediaDocument: pgContextsGTB.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: HcpGTI.hcpMainMenu, siteFooterMenu: HcpGTI.hcpHomeFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });

  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name;
  const bannerTitle = ""
  const carouselTitle = HcpGTI.hcpCarousel
  const footerTitle = HcpGTI.hcpFooter
  const exitPopupTitle = HcpGTI.hcpExitPopup
  const hcpSwitcherTitle = HcpGTI.hcpHCPSwitcher
  const topNavTitle = HcpGTI.hcpTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle })

  const hcpReferenceGTB = footRefHTMLBuilder({ blocks, title: HcpGTI.hcpFootRef });
  const testOrRetestYourPatientsSectionData = fullHTMLDataBuilder({ blocks, title: HcpGTI.hcpTakeActionPara });
  const callOutData = fullHTMLDataBuilder({ blocks, title: HcpGTI.hcpNaviCalloutPara });
  const { per, text1, text2 } = infographicDataBuilder({ blocks, title: HcpGTI.hcpInfographic });
  const pieInfoGraData = { 'per': `${per}`, 'circular_text': text1, 'right_text': text2 }
  const siteLogos: any = deriveSiteLogo({ blocks, title: HcpGTI.hcpSiteLogo })
  const bannerHTML = fullHTMLDataBuilder({ blocks, title: HcpGTI.hcpBannerHTML });
  const htmlData = fullHTMLDataBuilder({ blocks, title: HcpGTI.hcpGTMayPara });
  const retestingData = fullHTMLDataBuilder({ blocks, title: HcpGTI.hcpImpOfRetestPara });
  const calloutSectionData = fullHTMLDataBuilder({ blocks, title: HcpGTI.hcpCalloutSectionPara });
  const revealData = fullHTMLDataBuilder({ blocks, title: HcpGTI.hcpGTCanRevealPara });
  const knowData = fullHTMLDataBuilder({ blocks, title: HcpGTI.hcpDidYouPara });

  return (
    <div className='hcppage'>
      <HcpPage>
        <HcptestResetpatientSection className="hcppatientsection">
          {/* Retina Canada HCP - "Importance of Genetic Testing" page - Banner component - EN */}
          <HcpMobileBanner className="imp-gen-testing hcpmobilewrapper hcp-genetic-benefits-footer-wrapper">
            {props.pageContext !== null &&
              props.pageContext.metaInfo !== null && (
                <MetaTagContainer metaData={props.pageContext.metaInfo} />
              )}
            <Layout
              title={"siteTitle"}
              location={props.location}
              data={mainMenu}
              mdata={footerMenu}
              footerData={footerText}
              audience={audience}
              footref={hcpReferenceGTB}
              hcplinks={hcpSwitcher}
              exitPopData={exitData}
              topNavigation={topNavs}
              staticMediaFiles={htmlStaticFilesGTB}
              siteLogos={siteLogos}
              backToTopContent={retinaConfig.backToTopContent}
              modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
              modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
              gtmBackToTopLabel={HcpGTI.backToTopGTM}
              showRefContent={retinaConfig.showRefContent}
              hideRefContent={retinaConfig.hideRefContent}
              hcpValidate={retinaConfig.hcpValidate}
              preIndexUrl={retinaConfig.preIndexUrl}
              hcpHomeUrl={retinaConfig.hcpHomeUrl}
              hcpPrefix={retinaConfig.hcpPrefix}
              siteHomeUrl={retinaConfig.siteHomeUrl}
              footerClassName={HcpGTI.footerClassName}
              cpraFooter={true}
              cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
              cpraOT={true}
              cpraOTEnv={process.env.OT_ENV}
              cpraOTuuid={process.env.OT_UUID}
              {... { ...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
            >
              <PageWrapper className="pagewrapper importance-genetic">
                <ImportanceOfgeneticTesingWrapper className="imggentestwrap hcp-genetic-benefit-wrapper-container">
                  <MobileBanner className="mobile-banner clinical-trials-banner">
                    <HTMLRenderer data={htmlStaticFilesGTB} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                  </MobileBanner>
                  {/* Retina Canada HCP - "Importance of Genetic Testing" page - Icon & Text component -EN */}
                  <HTMLRenderer data={htmlStaticFilesGTB} html={htmlData} />
                  <HTMLRenderer data={htmlStaticFilesGTB} html={retestingData} />
                  {/* Retina Canada HCP - "Importance of Genetic Testing" page - Callout component-EN */}
                  <HTMLRenderer html={calloutSectionData} data={htmlStaticFilesGTB} tagName={`div`} className={`o-container`} />
                  {/* Retina Canada HCP - "Importance of Genetic Testing" page - Donut Chart component -EN */}
                  <CircularInfographics data={pieInfoGraData} audience={audience} />
                  <HTMLRenderer data={htmlStaticFilesGTB} html={knowData} />
                  <HTMLRenderer data={htmlStaticFilesGTB} html={revealData} />
                  <ContainerWrapper className="container blue-box-wrapper">
                    <TestorRetestPatientSectionWrapper>
                      <HTMLRenderer html={testOrRetestYourPatientsSectionData} data={htmlStaticFilesGTB} tagName='div' className="o-container" />
                    </TestorRetestPatientSectionWrapper>
                  </ContainerWrapper>
                  <EmerFooterBlock className="emr-footer-block imp-gens">
                    <FooterCalloutBlock className='o-container padding-cards-fix home-page-footer-us'>
                      <HTMLRenderer html={callOutData} data={htmlStaticFilesGTB} tagName='div' className="footer-block footer-block-bg" />
                    </FooterCalloutBlock>
                  </EmerFooterBlock>
                </ImportanceOfgeneticTesingWrapper>
              </PageWrapper>
            </Layout>
          </HcpMobileBanner>
        </HcptestResetpatientSection>
      </HcpPage>
    </div>
  );
};

export default GeneticTestingBenefitTemplate;

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: { eq: $id }) {
      relationships {
        ... HCPGTIPageQuery
      }
    }
  }
`;
