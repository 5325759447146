import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device';
import { font } from '../../../css/common/variables';

//Importance of Generic Testing UI Styles
export const DisclaimerFooterWrapper = styled.div`
	position: relative;
	top: 14px;
	color: white;
	top: 50px;
	padding-bottom: 50px;

	.disclaimer-para {
		@media ${device.tablet} {
			max-width: 718px;
			padding: 0 3rem;
			margin: auto;
		}
		@media ${device.ipadLandscapemin} {
			padding-left: 0;
			padding-right: 2.2rem;
			max-width: 1188px;
		}


		.disclaimer-para-gtb {
			cursor: default;
			z-index: 1000;
			position: relative;
			font-size: 14px;
			line-height: 1.22;
			margin-bottom: 0;
			font-family: ${font.gotham_book};

			@media ${device.desktopsignup} {
				font-size: 16px;
				line-height: 20px;
			}

			strong {
				font-family: ${font.gotham_bold};
			}
		}
	}
`;
export const ImportanceOfgeneticTesingWrapper = styled.div`
	.internal-banner__image {
		img {
			object-fit: cover;
			width: 100%;
			object-position: center center;
			height: 100%;
			display: block;

			@media ${device.laptopPros} {
				height: 100%;
    		object-position: 93% 0%;
			}

			@media ${device.ipadLandscape} {
				height: 100%;
    		object-position: 90% 0%;
			}
		}
	}

	.internal-banner__contant {
		.internal-banner__large-title,
		h1 {
			h1 {
				font-size: 5.3rem;
				line-height: 5.9rem;
				color: #fff;
				text-transform: uppercase;
				padding: 0 0 0px;
				max-width: 70%;
				margin: 0;
				font-weight: 700;

				@media ${device.laptopMedium} {
					text-align: center;
					width: 100%;
					margin: auto;
					max-width: 100%;
					font-size: 3.2rem;
					line-height: 3.8rem;
				}
			}
		}
	}

	.fullwidth-listing {
		padding: 80px 0 120px;

		@media ${device.laptopMedium} {
			padding: 27px 0 48px;
		}

		.o-container {
			.o-inner {
				padding: 0 100px;

				@media ${device.laptopMedium} {
					padding: 0;
				}

				.o-header {
					color: #000000;
					font-weight: 700;
					text-align: center;
					font-size: 4.2rem;
					line-height: 5rem;

					@media ${device.laptopMedium} {
						font-size: 2.5rem;
						line-height: 3rem;
					}

					strong {
						font-weight: 700;
					}

					span {
						color: #8000BE;
					}
				}

				.full-listing-block {
					display: inline-block;
					padding: 0;
					list-style: none;
					margin: 40px 0 0;

					@media ${device.laptopMedium} {
						margin: 20px 0 0;
					}

					li {
						&.o-header--h4 {
							@media ${device.ipadLandscapemin} {
								padding: 12px 0 12px 48px;
							}
						}
					}
					li {
						color: #000000;
						line-height: 3.2rem;
						font-family: "Gotham";
						font-weight: normal;
						font-size: 2.7rem;
						position: relative;
						text-align: left;
						display: inline-block !important;
						padding: 12px 0 12px 48px;
						@media ${device.laptopMedium} {
							line-height: 2.4rem;
							position: relative;
							padding: 8px 0 8px 40px;
							color: #000000;
							font-size: 2rem;
							text-indent: unset;
						}
						span {
							display: inline-block;
							background-color: #8000BE;
							color: #ffffff;
							border-radius: 50%;
							text-align: center;
							width: 38px;
							height: 38px;
							line-height: 38px;
							font-size: 2.2rem;
							margin: 0 4px 0 0;
							text-indent: 0;
							position: absolute;
							left: 0px;
							top: 25px;
							margin-right: 20px;

							@media ${device.laptopMedium} {
								width: 32px;
								height: 32px;
								line-height: 32px;
								font-size: 2rem;
								position: absolute;
								left: 0;
								margin-right: 0;
								margin-top: -3px;
								text-indent: unset;
							}
							@media ${device.desktopsignup} {
								top: 16px;
							}
						}

						a {
							color: #000000;
							cursor: default;
							text-decoration: none;
						}
						sup {
							&.gen-test-sup1 {
								top: -1rem;
							}
						}
						sup {
							&.gen-test-sup2 {
								top: -1rem;
							}
						}
					}
				}
			}
		}
	}

	.generic-testing-benefits {
		padding: 0px 140px;
		position: relative;

		@media ${device.laptopMedium} {
			padding: 0;
		}

		&:before {
			content: "";
			background-color: #00FFD9;
			position: absolute;
			top: 120px;
			left: 0;
			height: calc(100% - 305px);
			width: 100%;
		}

		.importance-rest--block {
			padding: 0 0 50px;
			background-color: #F7F7F7;
			box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
    	position: relative;

			@media ${device.laptopPros} {
				margin: 0 28px;
				padding: 0 0 30px;
			}

			&:before {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				position: absolute;
				top: 0;
				width: 100%;
				height: 22px;
				left: 0;
				right: 0;

				@media ${device.laptopMedium} {
					height: 12px;
				}
			}

			.faq-block {
				padding-top: 62px;
				position: relative;

				@media ${device.laptopMedium} {
					padding-top: 42px;
				}

				h2 {
					color: #000000;
					text-align: center;
					padding-bottom: 40px;
					font-size: 4.2rem;
					font-weight: 600;
					line-height: 5rem;

					@media ${device.laptopPros} {
						width: 80%;
						margin: auto;
						display: block;
					}

					@media ${device.laptopMedium} {
						padding-bottom: 20px;
						font-size: 2.5rem;
    				line-height: 3rem;
					}
				}

				.faq-question {
					max-width: 85%;
    			margin: 0 auto 50px;
					position: relative;

					@media ${device.laptopMedium} {
						margin: 0 auto 20px;
						text-align: center;
					}

					img {
						position: absolute;
						top: 5px;
						left: 0;

						@media ${device.laptopMedium} {
							width: 50px;
							position: unset;
							left: unset;
							top: unset;
							margin-bottom: 15px;
							height: auto;
						}
					}

					.faq-answer {
						padding-left: 110px;

						@media ${device.laptopMedium} {
							padding-left: unset;
							text-align: center;
						}

						.o-paragraph {
							font-weight: 500;
							line-height: 3.2rem;
							color: #000000;
							font-size: 2.2rem;

							@media ${device.laptopMedium} {
								font-size: 1.8rem;
								line-height: 2.6rem;
							}

							strong {
								font-weight: 700;
							}

							sup {
								font-size: 60%;
								top: -0.5em;
								line-height: 0;
								vertical-align: baseline;
								position: relative;
							}
						}
					}

					&:nth-child(3) {
						img {
							transform: rotate(15deg);
							width: 68px;
							height: 97px;
						}
					}

					&:last-child {
						img {
							width: 70px;
							height: 85px;

							@media ${device.laptopMedium} {
								width: 40px;
								height: auto;
							}
						}
					}
				}
			}
		}
	}

	.case-studies--block {
		padding: 88px 0 0;

		@media ${device.laptopMedium} {
			padding: 10px 0 0;
		}

		.o-columns--row {
			justify-content: center;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: stretch;
    	margin: 30px 0 0;

			@media ${device.laptopMedium} {
				display: block;
			}
			.o-columns--two {
				max-width: 450px;
				margin: 0 20px;
				padding: 30px 25px 100px;
				box-sizing: border-box;
				position: relative;
				text-align: center;
				width: 48.5%;
				background-color: #8000BE;
				display: block;

				@media ${device.laptopMedium} {
					max-width: 340px;
					margin: 20px auto;
					width: 100%;
					padding: 15px 20px !important;
				}
				.media--image {
					img {
						display: block;
						margin: 0 auto;

						@media ${device.laptopMedium} {
							margin: 10px auto;
							width: 50px;
						}
					}
				}

				img {
					display: none;
				}

				.o-paragraph {
					margin: 30px auto;
					width: 100%;
					padding: 0;
					text-align: center;
					color: #fff;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;

					@media ${device.laptopMedium} {
						line-height: 22px;
						width: 100%;
						font-size: 1.8rem;
						margin: 16px 0 32px;
					}
				}

				.o-button {
					position: absolute;
					bottom: 40px;
					left: 0;
					right: 0;
					margin: 0 auto;
					display: inline-block;
					max-width: 252px;
					padding: 10px;
					color: #8000BE;
					background-color: #fff;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
					text-decoration: none;
					z-index: 1;
					vertical-align: top;
					overflow: hidden;
					padding: 10px 25px;
					border: none;
					letter-spacing: 0;
					border-radius: 6px;
					background: #fff;
					transition: all 0.3s ease;
					cursor: pointer;

					@media ${device.laptopMedium} {
						margin: 0 auto 10px;
						position: unset;
						font-size: 1.8rem;
						display: block;
						max-width: 245px;
						width: 100%;
					}

					&:hover {
						color: #fff;
						background-color: #7323CA;
					}

					&:after {
						content: '';
						display: block;
						width: 17px;
						height: 14px;
						float: right;
						position: relative;
						top: 10px;
						left: 7px;

						@media ${device.laptopMedium} {
							left: 0;
						}
					}
				}
			}

		}
	}

	.genetic-cause-wrapper {
		.o-inner {
			padding: 0;

			.genetic-cause {
				width: 80%;
				padding: 120px 50px;
				margin: 0 auto;

				@media ${device.desktopsignup} {
					padding: 88px 40px;
				}

				@media ${device.laptopMedium} {
					width: 100%;
					margin: 20px 0 30px;
					padding: 24px 0;
					flex-direction: column;
				}

				.genetic-cause__right-text-secion {
					padding-left: 60px;

					@media ${device.laptopMedium} {
						padding-left: 0;
					}
				}
			}
		}
	}

	.genetic-cause__right-text-secion {

		@media ${device.desktopsignup} {
			width: calc(100% - 236px);
		}

		p {
			line-height: 3.4rem;
		}
	}

	.genetic-cause__left-img-secion {
		.circle_inbox {
			.percentblock {
				font-size: 2.2rem;
				line-height: 2.6rem;
				padding-left: 5px;

				.percentcount {
					.percent_text {
						font-weight: 700;
						font-size: 5.5rem;
    				line-height: 5rem;
					}
					small {
						font-weight: 400;
						font-size: 1.76rem;
    				line-height: 1rem;
					}
				}
			}
		}
	}

	.image-points-section {
		padding-bottom: 88px;

		@media ${device.laptopMedium} {
			padding-bottom: 40px;
		}

		h2 {
			width: 60%;
			display: block;
			margin: auto;
			font-size: 4.2rem;
			font-weight: 700;
			line-height: 5rem;
			color: #000000;
			text-align: center;

			@media ${device.laptopMedium} {
				padding: 0 30px;
				width: 100%;
				display: block;
    		margin: auto;
				text-align: center;
				font-size: 2.5rem;
				line-height: 3rem;
			}
		}

		.listing-btm-section {
			padding: 50px 0 0;
			display: flex;
			flex-wrap: wrap;
			align-items: baseline;

			@media ${device.laptopMedium} {
				display: block;
				padding: 20px 0 0;
			}

			.media--image {
				width: 47.4%;
				margin: 0 auto;

				@media ${device.desktopStart} {
					width: 48%;
				}

				@media ${device.laptopL} {
					width: 46%;
				}

				@media ${device.extraLargeDesktop} {
					width: 46.5%;
					max-width: unset;
				}

				@media ${device.laptopMedium} {
					width: 100%;
					margin: 0 auto 17px;
				}

				.gatsby-image-wrapper {
					>div {

						@media ${device.desktopStart} {
							max-width: 805px !important;
						}
					}

					img {
						@media ${device.desktopStart} {
							max-width: 805px;
							width: 805px;
						}

						@media ${device.extraLargeDesktop} {
							max-width: 884px;
							width: 884px;
						}
					}
				}
			}

			.right-listing-points {
				margin: initial;
				align-items: flex-end;
				justify-content: end;
				padding-left: 48px;
				width: 51.6%;
				padding-right: calc((100vw - 1168px) / 2);
				display: table;

				@media ${device.laptopMedium} {
					margin-top: 30px;
					padding-left: 28px;
					padding-right: 28px;
					width: 100%;
					margin: 30px 0 0 0;
				}

				.o-paragraph {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
					color: #000000;

					@media ${device.laptopMedium} {
						margin-top: 0px;
						font-size: 1.8rem;
						line-height: 2.6rem;
					}
				}

				.listing-section {
					margin-top: 28px;
					margin: auto;
    				display: table;
					padding: 0;

					@media ${device.laptopMedium} {
						margin-top: 15px;
					}

					li {
						padding: 0 0 24px 25px;
						font-family: 'Gotham-Book';
						display: inline-block;
    					position: relative;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						color: #000000;

						@media ${device.laptopMedium} {
							padding: 0px 0 15px 24px;
							margin-top: 0px;
							font-size: 1.8rem;
    						line-height: 2.2rem;
						}

						&:before {
							content: "";
							position: absolute;
							background-color: #8000BE;
							width: 8px;
							height: 8px;
							top: 12px;
							margin: auto;
							left: 0;
							@media ${device.laptopMedium} {
								left: 1px;
							}
						}

						sup {
							font-size: 60%;
							line-height: 0;
							vertical-align: baseline;
							position: relative;
							top: -0.5em;
						}
					}
				}
			}
		}
	}

	.blue-box-wrapper {
		margin-bottom: 120px;

		@media ${device.tablet} and ${device.ipadLandscape}  {
			padding: 0 5rem;
		}

		@media ${device.laptopMedium} {
			margin-bottom: 50px;
		}

		.o-genes-wrap {
			margin: 0 40px;

			@media ${device.laptop} and ${device.midDesktopMax} {
				margin: 0;
			}

			@media ${device.laptopMedium} {
				margin: 0;
			}

			.info-box-2 {
				padding: 40px 30px;

				@media ${device.laptopMedium} {
					padding: 20px 35px;
				}
			}
		}
	}

	.generic-testing-benefits-cover-bg.o-section--cover-bg {
		.o-container {
			max-width: 1188px;
			margin-left: auto;
			margin-right: auto;
			padding: 0 3rem;

			h2.o-header {
				font-size: 4.2rem;
				font-weight: 700;
				line-height: 5rem;
				color: rgb(0, 0, 0);
				text-align: center;
				padding: 0px 0px 40px;
			}

			.o-columns {
				margin: 0px auto 140px;
				width: calc(100% + 30px);
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: rgb(0, 0, 0);
				text-align: center;
			}
		}
	}

	.emr-footer-block {
		.footer-block {
			&.footer-block-bg {
				background: transparent;
			}
		}
	}
`;

export const EmerFooterBlock = styled.div`
	.footer-block {

		&.footer-block-bg {
			background-color: transparent;
			padding-top: 0;
		}

		.footer-block--row {
			.text-button-wrap {
				a {
					top: auto !important;
				}
			}
			@media ${device.laptopMedium} {
				.text-button-wrap {
					a {
						margin-left: 0;
						margin-top: 15px;
					}
				}
			}

			.footer-inner-block {

				@media ${device.ipadLandscape} {
					padding: 32px 16px 20px !important;
				}

				@media ${device.laptopMedium} {
					&:last-child {
						margin-top: 15px;
					}
				}
				span {

					font-family: "Gotham-Book" !important;

					a {
						&:after {
							margin-top: 7px !important;
						}
						font-size: 2.2rem !important;
						font-weight: 500 !important;
						line-height: 2.4rem !important;

						@media ${device.laptopMedium} {
							font-size: 1.8rem !important;
							line-height: 1.22 !important;
						}
					}
				}

				&:before {
					background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
				}

				h3.o-common-title {
					font-size: 2.7rem !important;
					line-height: 3.8rem !important;
					font-family: ${font.gotham_bold} !important;
					@media ${device.laptopMedium} {
						font-size: 2rem !important;
						line-height: 1.2 !important;
					}
				}
			}
		}
	}

	&.gen-footer-block {
		.footer-block {
			&.footer-block-bg {
				background: transparent;
				padding-top: 0;
			}
		}
	}

	&.imp-gens {
		.footer-block {
			&.footer-block-bg {
				background: transparent;
				padding-top: 0;

				.footer-inner-block {
					&:first-child {
						&:before {
							content: "";
							background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
							width: 100%;
							height: 16px;
							position: absolute;
							top: 0;
							left: 0;
							border-radius: 5px 5px 0 0;
						}
					}

					&:last-child {
						&:before {
							background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
						}
					}
				}
			}
		}
	}
`;

export const ContainerWrapper = styled.div`
	@media ${device.minimumDesk} {
		max-width: 1140px;
		margin: 0 auto;
		padding: 0 15px;
	}

	@media ${device.largeS} {
		max-width: 1140px;
		margin: 0 auto;
		padding: 0 15px;
	}

	&.genes-wrapper {
		margin-bottom: 30px;

		@media ${device.laptopMedium} {
			margin-bottom: 0;
		}

		.o-container {
			.o-inner-wrapper-gens {
				padding: 0 50px;

				@media ${device.laptopMedium} {
					padding: 0;
				}

				@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
					padding: 0;
				}

				.o-genes-wrap {
					padding: 27px 90px;
					margin: 22px auto 0;
					box-shadow: 0 8px 24px 0 rgb(0 0 0 / 16%);
					display: flex;
					align-items: center;
					background-color: #300064;
					position: relative;
					width: 100%;

					@media ${device.laptopMedium} {
						margin: 0;
						padding: 29px 42px 25px 42px;
						display: block;
					}

					.info-box-2-icon {
						float: left;

						@media ${device.laptopMedium} {
							float: none;
						}

						img {
							left: 95px;
							width: 80px;

							@media ${device.laptopMedium} {
								margin-top: 0;
								width: 44px;
								right: 0;
								text-align: center;
								display: block;
								margin: auto;
							}
						}
					}

					.step-info-box {
						display: flex;
						padding: 0;
						flex-wrap: unset;
						width: auto;
						align-items: unset;

						@media ${device.laptopMedium} {
							display: block;
						}
						.o-container {
							padding: 0;
						}
						.o-inner {
							padding: 0;
						}
						.info-box {
							display: flex;
							@media ${device.laptopMedium} {
								display: block;
							}
							.infobox-middle-para {
								width: min-content;
								line-height: 20px;
								margin: auto 0;
								padding-left: 12px;
								@media ${device.laptopMedium} {
									width: 100%;
									margin: 10px 0 0;
									padding: 0;
									line-height: 2.4rem;
									display: flex;
									justify-content: center;
									align-items: center;
								}
								span {
									padding-top: 3px;
									font-size: 4.2rem;
									line-height: 40px;
									display: inline-block;
									@media ${device.laptopMedium} {
										font-size: 3.2rem;
										line-height: 3.5rem;
										padding: 0 6px;
									}
								}
							}
							.genes-info-box {
								text-align: left;
								margin: 0;
								padding: 0 0 0 32px;
								font-size: 2.2rem;
								line-height: 3.2rem;
								@media ${device.laptopMedium} {
									text-align: center;
									padding: 0;
									margin-top: 10px;
									font-size: 2rem;
									line-height: 2.4rem;
								}
							}
						}
						.over-genes-wrapper {
							color: #fff;
							font-weight: 500;
							text-align: center;
    						line-height: 20px;
							font-size: 2.2rem;
							margin: 7px 0 0;
							width: auto;

							@media ${device.laptopMedium} {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 100%;
								line-height: 2.4rem;
								margin-top: 10px;
								font-size: 2rem;
							}

							span {
								line-height: 40px;
								padding-top: 3px;
								color: #00FFD9;
   								display: block;
								font-size: 4.2rem;

								@media ${device.laptopMedium} {
									font-size: 3.2rem;
									padding: 0 6px;
								}
							}
						}

						.over-genes-para {
							padding: 0 0 0 32px;
							font-size: 2.2rem;
							line-height: 3.2rem;
							font-family: "Gotham-Book";
							text-align: left;
    						color: #fff;
							font-weight: 500;
							margin: 0;

							@media ${device.laptopMedium} {
								font-size: 2rem;
    							line-height: 2.4rem;
								padding: 0;
								text-align: center;
    							margin-top: 10px;
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}
`;

export const TestorRetestPatientSectionWrapper = styled.div`
	.o-container {
		.info-box-2 {
			display: flex;
			align-items: center;
			background-color: #300064;
			padding: 40px 100px;
			position: relative;
			flex-wrap: wrap;
			width: 100%;

			span {
				color:#00FFD9;
			}

			@media ${device.laptopMedium} {
				padding: 20px 10px;
				margin: 0 0 18px 0;
				justify-content: center;
			}

			p {
				width: 80%;
				margin: auto;
				text-align: center;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #fff;

				@media ${device.laptopMedium} {
					width: 100%;
					font-size: 1.8rem;
					line-height: 2.6rem;
				}
			}
		}
	}
`;