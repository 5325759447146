export const HcpGTI: any = {
  hcpMainMenu: "Main Navigational Menu HCP GCSO - Retina Global",
  hcpFooterMenu: "Footer Navigation Menu HCP GCSO - Retina Global",
  hcpCarousel: "",
  hcpBanner: "Banner content - Genetic Testing Benefits HCP - Retina Global",
  hcpBannerHTML: "Banner - HTML - Genetic Benefits - HCP - US Global",
  hcpFooter: "Retina Global Footer HCP - US - Global",
  hcpExitPopup: "Retina Canada Exit Popup New - Global",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Global",
  hcpTopNav: "Top Navigational Menu HCP US - Global",
  hcpSiteLogo: "Site logos - Global",
  hcpFootRef: "Reference - Genetic Testing Benefits - Retina Global",
  hcpInfographic: "Infographic - Genetic Testing Benefits - Retina Global",
  hcpGTMayPara: "Genetic Testing May give - Genetic Testing Benefits - Retina Global",
  hcpImpOfRetestPara: "The Importance Of Retesting - Genetic Testing Benefits - Retina Global",
  hcpGTCanRevealPara: "Genetic Testing Can Reveal - Genetic Testing Benefits - Retina Global",
  hcpDidYouPara: "Did You Know - Genetic Testing Benefits - Retina Global",
  hcpCalloutSectionPara: "Callout Section - Genetic Testing Benefits HTML - Retina Global",
  hcpTakeActionPara: "Take action to confirm HTML - GTB HCP US Global",
  hcpNaviCalloutPara: "Footer callout - HTML GTB HCP US Global",
  backToTopGTM: "Back to Top - genetic testing benefits",
  footerClassName: "genetic-testing-importance-footer",
}